@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200;300;400;500;600;700&display=swap');

body {
    background-color: #f3f4f5 !important;
    font-family: 'Roboto', sans-serif !important;
    overflow: hidden;
}

* {
    font-family: 'Roboto', sans-serif !important;
}

/* WRAP COMP */

.main_container {
    height: 100vh !important;
    overflow: hidden;
    font-family: 'Roboto';
}

.main-screen {
    display: flex;
    background: transparent;
    margin-top: 55px;
    height: calc(100vh - 55px);
    overflow-y: auto;
    font-family: 'Roboto';
}

.main-container {
    margin: 0px;
    transition: .5s;
    font-family: 'Roboto';
}

.main-section {
    height: 100%;
    width: 100%;
    overflow: auto;
    font-family: 'Roboto';
}

/* AUTH PAGE */
.mainAuthPage {
    height: 100vh;
    flex-direction: column;
    background: #fff;
    width: 100%;
    overflow-y: auto;
    display: flex;
    align-items: center;
}

.main_cont_login {
    height: 100%;
    display: flex;
    align-items: center;
    width: 405px;
    margin: 0 auto;
    /* position: absolute;
    top: 40%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%); */
}

.wbs_logo {
    width: 400px;
    margin: 10px 2px 20px 0px;
}

.login-controls {
    width: 100%;
}

.login-input {
    margin-bottom: 15px;
}

.login-input input {
    background: #fff;
    color: #000;
    font-size: 12px;
    border: 1px solid grey;
    border-radius: 7px;
    padding: 5px 10px;
    width: 100%;
    height: 30px;
    font-weight: 600;
}

.login-input {
    background: #fff !important;
    color: #000 !important;
    font-size: 12px !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 7px !important;
    padding: 5px 10px !important;
    width: 100% !important;
    height: 40px !important;
    font-weight: 600 !important;
}

.pw-input .ant-input {
    background: #fff !important;
    color: #000 !important;
    font-size: 12px !important;
    border-radius: 7px !important;
    /* padding: 5px 10px !important; */
    width: 100% !important;
    min-height: 30px !important;
    max-width: 100% !important;
}

.login-btn {
    background: #1c7dff !important;
    color: #fff !important;
    border-radius: 7px !important;
    border: none !important;
    text-align: center !important;
    width: 100% !important;
    margin-bottom: 15px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.login-btn .ant-btn-loading-icon .anticon-loading {
    display: flex !important;
    align-self: center;
    justify-content: center;
}

.google-btn {
    border-radius: 7px !important;
    border: none !important;
    text-align: center !important;
    width: 100% !important;
    margin: 2px 0px 15px 1px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 40px !important;
}

.file-text {
    color: #8a8888;
}

.file_upload_modal_cont .ant-modal-content {
    background-color: white;
    padding: 10px;
    border-radius: 20px !important;
}

.file_upload_modal_cont .ant-modal-header {
    text-align: center;
    /* border-bottom: 0px !important; */
}

.file_upload_modal_cont .ant-modal-footer {
    padding: 10px 30px !important;
    border: 0px !important;

}

.file_upload_modal_cont .ant-modal-close-x {
    line-height: 70px !important;
}

.header_modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header_modal .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px !important;
}

.resetpass-main {
    width: 100%;
}

.resetpass-main-img {
    text-align: center;
}

.resetpass-main-img img {
    width: 200px;
    margin-bottom: 15px;
}

.resetpass-main-content {
    width: 80%;
    margin: 0 auto;
}

.resetpass-main-content h4 {
    margin-bottom: 10px;
    font-size: 15px;
}

.resetpass-main-content .ant-form-item {
    margin-bottom: 0px !important;
}

.resetpass-main-img {
    text-align: center;
}

.resetpass-main-img img {
    width: 200px;
    margin-bottom: 15px;
}

.resetpass-main-content {
    width: 80%;
    margin: 0 auto;
}

.resetpass-main-content h4 {
    margin-bottom: 10px;
    font-size: 15px;
}

.resetpass-main-content .ant-form-item {
    margin-bottom: 0px !important;
}

.forgotpas {
    color: #8a8888;
    cursor: pointer;
    margin-bottom: 4px;
}

.mainheader {
    background: white;
    z-index: 11;
    position: relative;
    font-family: 'Roboto';
}

.Top-header {
    display: flex;
    justify-content: space-between;
    padding: 2px 30px 2px 10px;
    height: 54px;
}

.header_left {
    display: flex;
    align-items: center;
}

.logomain {
    width: 150px;
    margin-left: 10px;
    height: 52px;
    /* margin-top: -10px; */
    cursor: pointer;
}

.right_side {
    display: flex;
    align-items: center;
    justify-content: center;
}

.name_space {
    font-size: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* margin-right: 28px; */
}

.login-input.ant-input-affix-wrapper-focused,
.login-input.ant-input-affix-wrapper:focus,
.inquery-area.ant-input:focus,
.antd-input-border-customize.ant-input-affix-wrapper-focused,
.antd-input-border-customize.ant-input-affix-wrapper:focus {
    /* border: 0.5px solid #40a9ff !important; */
    outline: none;
    box-shadow: none !important;
}

.antd-input-border-customize {
    border-radius: 4px;
    font-size: 14px;
    padding: 5px;
    border: 0.5px solid #d3cfcf !important;
    box-shadow: none !important;
}

.antd-input-border-customize:focus {
    border: 0.5px solid #40a9ff !important;
    outline: none;
    box-shadow: none !important;
}

.header-menu .content_li ul,
li {
    margin-right: 0px !important
}

.display-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.settings-head {
    display: flex;
    padding: 15px 0px;
    justify-content: space-between;
    align-items: center;
}

.settings-head h1 {
    color: #3a4d77;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 25px;
}

.setting-wrapper {
    background: #fff;
    margin: 0px 0px 10px;
    border-radius: 12px;
    height: calc(100vh - 185px);
}

.tabpanel-main-domain {
    /* overflow-y: auto;
    height: calc(100vh - 155px); */
    padding: 24px;
}

.searchContainer-setting {
    margin-right: 10px;
    margin-top: -33px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.searchContainer-setting>input {
    margin-right: 15px !important;
    width: 24% !important;
}

.domain_table {
    margin: 20px auto;
    width: 100%;
    height: calc(100vh - 255px);
    overflow-y: auto;
}

.domain_table .ant-table th {
    padding: 14px !important;
}

.domain_table .ant-table-tbody>tr>td {
    padding: 0px !important;
    padding-right: 4px !important;
    padding-left: 15px !important;
}

.domain_table_modal table {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}

.domain_table_modal .ant-table-thead>tr>th {
    border-bottom: none !important;
}

.domain_table_modal tr {
    padding: 0px !important;
    /* background: #e8e9ec !important; */
}

.domain_table_modal th {
    background: #e8e9ec !important;
}

.domain_table_modal .ant-table-tbody>tr>td {
    padding: 0px 16px !important;
    border-right: 1px solid #f0f0f0 !important;
}

.domain_table_modal .ant-table-tbody>tr>td:nth-child(2) {
    padding: 0px 16px !important;
}

.documentpage-width>.MuiDataGrid-main {
    flex-wrap: wrap;
    overflow-x: auto !important;
    width: 100%;
}

.doc_table .css-aop0yh .MuiDataGrid-cell,
.dynamic-table-main .css-aop0yh .MuiDataGrid-cell {
    background-color: #fff !important;
}

.doc_table .css-1pzb349,
.doc_table .css-qvtrhg-MuiDataGrid-virtualScroller {
    min-height: 100px !important;
}

.css-aop0yh .MuiDataGrid-columnHeader,
.doc_table .css-aop0yh .MuiDataGrid-columnHeader,
.doc_table .css-y599qu,
.doc_table .css-1iyq7zh-MuiDataGrid-columnHeaders {
    background-color: #e8e9ec !important;
    font-size: 16px !important;
    padding: 12px !important;
}

.doc_table .css-aop0yh .MuiDataGrid-withBorderColor,
.doc_table .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-withBorderColor {
    border-color: #fff !important;
}

.doc_table .css-1pzb349,
.doc_table .css-qvtrhg-MuiDataGrid-virtualScroller {
    overflow: hidden;
    background-color: #fff !important;
    padding: 12px !important;
}

.doc_table .css-aop0yh .MuiDataGrid-withBorderColor,
.doc_table .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-withBorderColor {
    border-color: rgb(211 209 209) !important;
}


.doc_table .css-aop0yh .MuiDataGrid-columnHeader,
.doc_table .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-columnHeader {
    border-right: 0.5px solid rgb(211 209 209) !important;
}

.doc_table .css-aop0yh .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell,
.doc_table .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    border-right: 0.5px solid rgb(244 241 241) !important;
    border-bottom: 0.5px solid rgb(244 241 241) !important;
}

.doc_table {
    padding: 10px 5px 20px 5px;
    width: 100%;
}

.doc_table .css-ky9ydx,
.doc_table .css-zil1cx-MuiDataGrid-detailPanel {
    border-bottom: 0.5px solid rgb(244 241 241) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.doc_table .ant-table-tbody>tr>td {
    padding: 10px;
}

.pagination-list-end {
    align-items: flex-end;
}

.pagination-list {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pagination-list ul li {
    list-style-type: none !important;
    margin: 10px 5px 0px 5px !important;
    padding: 0;
    background: #fff;
    border-radius: 4px !important;
}

.pagination-list ul li button {
    margin: 0px !important;
}

.pagination-list ul li button:hover {
    background-color: rgba(25, 118, 210, 0.04) !important;
    border: 1px solid #1c7dff !important;
}

.pagination-list-end {
    align-items: flex-end;
}

.rowsPerPage {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    /* width: 180px; */
    /* flex-direction: column; */
}

.rowsPerPage p {
    padding: 0px !important;
    margin: 0px !important;
}



#root>div>div>div:nth-child(2)>div:nth-child(2)>div>div>div>div>div:nth-child(2)>div.right_container>div>div>div.MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-aop0yh>div.MuiDataGrid-main.css-opb0c2>div:nth-child(3),
.dynamic-table-main .MuiDataGrid-main> :nth-child(3),
.MuiDataGrid-main> :nth-child(3),
.dynamic-table-main .MuiDataGrid-root.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-kzyyzb-MuiDataGrid-root>div.MuiDataGrid-main.css-204u17-MuiDataGrid-main>div:nth-child(3) {
    display: none !important;
}

.admin-table-avatar {
    display: flex !important;
    align-items: center !important;
}

.topsettingtab {
    min-height: 55px !important;
}

.tabpanel-main {
    overflow-y: auto;
    height: calc(100vh - 210px);
}

.tab-section-dtl {
    flex: 1;
    overflow-y: auto;
}

.tabdtl-img {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tabdtl-img-left {
    display: flex;
    align-items: center;
    justify-content: center;

}

.tabdtl-img-left img {
    width: 120px;
    height: 120px;
    padding: 10px;
    object-fit: contain;
    border-radius: 50%;
    box-shadow: 1px 1px 6px #1c7dffd6;

}

.tabdtl-img-left p {
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 25px;
    color: #3a4d77;
    padding-left: 20px;
}

.uploadbtndes {
    background: transparent;
    border: 1px solid #c4c4c475;
    padding: 5px;
    border-radius: 5px;
    color: #939393;
}

.mui_btn {
    display: inline-flex;
    justify-content: center !important;
    background-color: #fff !important;
    padding: 8px !important;
    border-radius: 4px !important;
    color: black !important;
    border: 1px solid #1c7dff !important;
    font-weight: normal !important;
    text-transform: initial !important;
    box-shadow: none !important;
}

.upl_btn_icon svg {
    margin-right: 4px;
    margin-top: -4px;
    font-size: 20;
    cursor: pointer;
}

.tab-section-dtl-sub {
    width: 90%;
    border-radius: 10px;
    border: 1px solid #c4c4c475;
    padding: 10px;
    margin: 0 auto;
}

.mr-top {
    margin-top: 10px !important;
}

.tabdtl-img-per {
    font-size: 20px;
    font-weight: 700;
}

.tabdtl-img-rig-dtl {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.tabdtl-img-rig-dtl p {
    width: 50%;
    padding: 0px 10px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 17px;
}

.tabdtl-img-rig-dtl p span {
    display: block;
    padding-bottom: 2px;
}

.graycolour {
    color: #b3b0b1;
    font-size: 19px;
    font-weight: 700;
}

.main-con {
    padding: 22px 24px;
}

.entitlement-box {
    margin: 0 auto;
    width: 90%;
}

.entry_heading>h2 {
    /* font-weight: bold; */
    text-align: center;
}

.end-date {
    width: 200px !important;
}

.header-btn {
    height: 38px !important;
    width: 80px !important;
    border-color: transparent !important;
    font-family: 'inter';
    border-radius: 6px !important;
}