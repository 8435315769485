.privacy-policy {
    padding: 18px;
    background-color: white !important;
}

.main-blogs-privacy>p {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
    margin: 0;
    text-align: justify;
}

.main-blogs-privacy>h3 {
    font-size: 15px;
}

.main-blogs-privacy ul {
    padding: 0px;
}

.heading-main-docs {
    font-size: 16px !important;
    font-weight: 700 !important;
    margin: 10px 0 0 0 !important;
}

.privacy-policy .heading-main-docs {
    color: #3a4d77 !important;
}

.ploicytable {
    width: 100%;
    border-collapse: collapse;
}

/* .main-blogs-privacy > th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
} */
.main-blogs-privacy table td,
.main-blogs-privacy table th {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

.ploicytable th {
    background-color: #f2f2f2;
    color: #3a4d77 !important;
}