.pricing_main_div {
    margin: 0px 0px;
    padding: 40px 22px;
    position: relative;
    /* min-height: 100vh; */
}

.pricing_inner_div {
    width: 100%;
    margin: auto;
    background: #ffffff;
    padding-top: 25px;
    border-radius: 20px;
}

.footer_fix {
    height: 100px;
    position: absolute;
    bottom: 0;
    right: -25px;
    left: -24px;
}

.pricing_heading_div {
    display: flex;
    justify-content: center;
    text-align: center;
}

.pricing_logo_div {
    width: 30%;
    /* margin: auto; */
    display: contents;
}

.cancel-subscription {
    width: 100%;
    margin-top: 10px;
    background: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 10px;
    transition: 500ms;
}

.pricing_logo {
    width: 100px;
    height: 100px;
    background-color: white;
    border-radius: 50px;
}

.start_now {
    font-size: 40px;
}

.pricing_main_div .ant-tabs-content {
    background: aqua !important;
    overflow-y: scroll !important;
    height: 100vh !important;
}

.pricing_card_div {
    width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    /* flex-wrap: wrap; */
}

.Pricing_box_card {
    width: 30% !important;
    margin-left: 3px !important;
}

.main_div_example_card {
    margin-bottom: 34px;
    position: relative;
}

.inner_example_card {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    margin-top: -1px;
}

/* .select-method {
    background-color: #FFF;
    padding: 5px 5px 5px 5px;
    border-radius: 50px 50px 50px 50px;
    box-shadow: 0 0 10px 0 rgb(47 44 44 / 50%)
} */

.biling-container {
    /* width: 400px;
    height: 62px;
    border-radius: 17px !important;
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 10px auto; */
    /* width: 400px; */
    height: 62px;
    border-radius: 17px !important;
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 10px auto;
    /* background: aqua; */
    align-items: center;
    padding: 5px 5px 5px 5px;
    border-radius: 50px 50px 50px 50px;
    transition: 500ms;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

/* .biling-container:hover,
.cancel-subscription:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
} */

.monthly-btn {
    height: 50px !important;
    width: 130px !important;
    /* padding-bottom: 10px; */
    border-color: transparent !important;
    font-family: 'Rubik';
    /* font-style: normal;
    font-weight: 600;
    font-size: 22px; */
    border-radius: 10px !important;
}

.active-plan {
    background: #1C74FF !important;
    color: white !important;
}

.yearly-btn {
    border-radius: 10px !important;
    height: 50px !important;
    width: 130px !important;
    /* padding-bottom: 10px; */
    border-color: transparent !important;
    /* font-style: normal;
    font-weight: 600;
    font-size: 22px; */
}

.main_inner_example_card {
    background-color: #ffffff;
    text-align: left;
    transition: all 0.3s ease-in-out;
    align-items: bottom;
    transform: translateZ(0);
    height: 470px;
    padding: 12px;
    border-radius: 16.6px;
    border: 0.3px solid #2C4061;
}

.pricing_plan_section {
    padding: 10px 0px 20px 0px;
}

.pricing_plan_section_top {
    padding: 0px 20px 0px 0px;
}

.entry_heading {
    color: black;
    font-weight: 600;
}

.fonts-heading {
    color: #1A1A1A;
    font-family: "Urbanist", Sans-serif !important;
    font-weight: 600;
}

.price_heading {
    color: #1A1A1A;
    font-family: "Urbanist", Sans-serif !important;
    font-size: 28px;
    font-weight: 800;
}

.ammouont_heading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pricing_plan {
    margin-top: 20px;
}

.pricingplan-Dtl {
    margin-top: 0px !important;
    display: flex;
    justify-content: center;
}

.pricing_plan_inner_section {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.PlansDtl {
    font-size: 17px;
}

/* .horizontal-line { */
    /* border-bottom: 0.5px solid #007EFD !important; */
/* } */

/* .border-bottom {
    border-bottom: 0.5px solid #7a7a7a;
} */

.billinglogo {
    width: 150px;
    cursor: pointer;
}

.plan_icon {
    margin-right: 10px;
    margin-top: 0px;
    color: #007EFD;
    font-size: 20px;
}

.plan_icon_feat {
    font-family: "Urbanist", Sans-serif;
    margin-right: 10px;
    /* margin-top: 5px; */
    color: black;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px
}

.gray {
    color: gray;
}

.plans {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
}

.get_started_btn {
    /* background: linear-gradient(to bottom right, #007EFD, #87CEEB); */
    background-color: #1C74FF;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    width: 70%;
    border-radius: 10px;
    /* border: 1px solid #87CEEB; */
    /* -webkit-clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%) !important */
}

.get_started_btn>button {
    border: none;
    background: none;
}

.get_started_btn:hover {
    /* border: 1px solid #87CEEB; */
    background: #3a4d77;
    color: #fff;
    transition: 0.5s;
}

.pricing_plan_inner_section_content {
    font-size: 14px;
}

@media(max-width: 1100px) {
    .Pricing_box_card {
        width: calc(50% - 3px) !important;
    }

    .pricing_card_div {
        flex-wrap: wrap;
    }
}

@media(max-width: 950px) {
    .pricing_card_div {
        flex-wrap: wrap;
    }

    /* .Pricing_box_card {
        margin: 63px auto;
        width: 350px !important;
    } */
}

@media(max-width: 764px) {
    .Pricing_box_card {
        width: 50% !important;
    }
}

.card_one {
    justify-content: flex-start !important;
    margin-top: 8px;
}

.cursor_disabled {
    cursor: not-allowed !important;
}